import { atom } from "recoil";

const checkPwState = atom({
  key: "checkPwState",
  default: false,
});

const businessPageIndexState = atom({
  key: "businessPageIndexState",
  default: 1,
});

const selectIndex = atom({
  key: "selectIndex",
  default: { first: 0, second: 0 },
});

export { checkPwState, selectIndex, businessPageIndexState };
