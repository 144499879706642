import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const userInfoState = atom({
  key: "userInfoState",
  default: {
    aToken: null,
    isLogin: null,
    user_id: null,
    user_name: null,
    user_type: null,
  },
});

const joinInfoState = atom({
  key: "joinInfoState",
  default: {
    checkAll: false,
    checkCondition: false,
    checkPersion: false,
    join_email: null,
    join_name: null,
    join_pw: null,
    join_pw_check: null,
    join_tel: null,
    join_id: null,
    company_name: null,
    company_number: null,
    company_post_number: null,
    company_adress1: null,
    company_adress2: null,
    company_tel: null,
    company_manager_name: null,
    company_manager_tel: null,
  },
  effects_UNSTABLE: [persistAtom],
});

export { userInfoState, joinInfoState };
