// 사용하고 싶은 옵션, 나열 되었으면 하는 순서대로 나열
const toolbarOptions = [
  ["link", "image"],
  [{ header: [1, 2, 3, false] }],
  ["bold", "italic", "underline", "strike"],
  ["blockquote"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
];

const fontSize = ["14px", "16px", "18px", "24px", "28px", "32px"];

// 옵션에 상응하는 포맷, 추가해주지 않으면 text editor에 적용된 스타일을 볼수 없음
const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "align",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "background",
  "color",
  "link",
  "image",
  "width",
];

// 이미지 처리를 하는 핸들러
const imageHandler = (quillRef) => {
  // 1. 이미지를 저장할 input type=file DOM을 만든다.
  const input = document.createElement("input");
  // 속성 써주기
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click(); // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
  // input이 클릭되면 파일 선택창이 나타난다.

  // input에 변화가 생긴다면 = 이미지를 선택
  input.addEventListener("change", async () => {
    const file = input.files[0];
    if (file.size > 2 * 1024 * 1024) {
      alert("이미지 파일 크기는 2MB까지만 가능합니다.");
      return;
    } else if (!file.type.match("image/.*")) {
      alert("이미지 파일만 업로드 가능합니다.");
      return;
    }

    // multer에 맞는 형식으로 데이터 만들어준다.
    const formData = new FormData();
    formData.append("img", file); // formData는 키-밸류 구조
  });
};

export { fontSize, formats, toolbarOptions, imageHandler };
